import React, { useContext } from 'react';

import { IAnalyticsService } from './Analytics';

const AnalyticsServiceContext = React.createContext<IAnalyticsService | null>(
  null
);

export const useAnalyticsService = (): IAnalyticsService => {
  const context = useContext(AnalyticsServiceContext);
  if (!context) {
    throw new Error(
      'useAnalyticsService must be used within a AuthServiceProvider'
    );
  }
  return context;
};

export const AnalyticsServiceProvider: React.FC<{
  analyticsService: IAnalyticsService;
  children: React.ReactNode;
}> = ({ analyticsService, children }) => {
  return (
    <AnalyticsServiceContext.Provider value={analyticsService}>
      {children}
    </AnalyticsServiceContext.Provider>
  );
};
