/**
 * Dev note: This is the connector service for analytics.
 * all auth events will be sent to this service.
 * and this service will send the events to the outside of this package.
 */

export interface CustomEventModel {
  eventName: string;
  eventProperties: any;
}

export interface IdentifyEventModel {
  phone: string;
}
export interface IAnalyticsService {
  trackEvent(event: CustomEventModel): void;
  identify(event: IdentifyEventModel): void;
}

export class AnalyticsService implements IAnalyticsService {
  onAnalyticsEvent: (event: CustomEventModel | IdentifyEventModel) => void;

  constructor(
    onAnalyticsEvent: (event: CustomEventModel | IdentifyEventModel) => void
  ) {
    this.onAnalyticsEvent = onAnalyticsEvent;
  }

  trackEvent(event: CustomEventModel): void {
    this.onAnalyticsEvent(event);
  }

  identify(event: IdentifyEventModel): void {
    this.onAnalyticsEvent(event);
  }
}
