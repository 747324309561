import { useCallback, useContext, useEffect, useState } from 'react';

import { PhoneValidatorResult } from '../../core/PhoneValidator';
import { Validator, ValidatorType } from '../../core/Validator';
import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';
import { AnalyticsEvents } from '../../services/analytics/AnalyticsEvents';
import { useAnalyticsService } from '../../services/analytics/AnalyticsServiceProvider';
import { useAuthService } from '../../services/authService/AuthServiceProvider';
import { useDataStore } from '../../services/dataStoreService/DataStoreProvider';
import {
  DFCAuthProcessorContext,
  IDFCAuthStep,
} from '../../stepProcessor/DFCAuthStepProcessorProvider';
// import { useAuthService } from 'services/authService/AuthServiceProvider';

import { PhoneNumberUtil } from '../../util/PhoneNumberUtil';
import { EnterPhoneStep } from './EnterPhoneStep';

interface EnterPhoneState {
  phoneNumber: string;
  error: string | undefined;
  isValidData: boolean;
}

//Responsibility of any Page State Hook
//1. State management
//2. Validation
//3. Error handling
//4. API calls
//5. Business logic
//6. Navigation
//7. No rendering
const useEnterPhone = (step: EnterPhoneStep) => {
  const { addNewStep } = useContext(DFCAuthProcessorContext);
  const dataStore = useDataStore();
  const authService = useAuthService();
  const analyticsService = useAnalyticsService();
  const phoneValidator = Validator.getValidator(
    ValidatorType.PHONE,
    step.validation
  );
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<EnterPhoneState>({
    phoneNumber: '',
    error: undefined,
    isValidData: false,
  });

  useEffect(() => {
    trackScreenViewEvent();
  }, []);

  const handleValidation = useCallback((newValue: string) => {
    // let valid = PhoneNumberUtil.validatePhoneNumber(newValue);
    let result = phoneValidator.validate(newValue) as PhoneValidatorResult;
    if (result.isValid) {
      setState((prevState) => ({
        ...prevState,
        isValidData: true,
        error: undefined,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isValidData: false,
        error: 'Must be a 10 digit number. ',
      }));
    }
    return result.isValid;
  }, []);

  const handlePhoneNumberChange = (value: string) => {
    handleValidation(value);
    setState((prevState) => ({
      ...prevState,
      phoneNumber: value,
    }));
  };

  const handleSubmit = () => {
    if (!state.isValidData) {
      return;
    }

    let phone = PhoneNumberUtil.getUSPhoneNumber(
      PhoneNumberUtil.unFormattedPhoneNumber(state.phoneNumber)
    );

    //identify user with phone number
    analyticsService.identify({ phone: phone });
    trackNextButtonEvent();

    setSubmitting(true);
    authService
      .submitPhoneNumber(phone)
      .then((nextStep: IDFCAuthStep) => {
        analyticsService.trackEvent({
          eventName: AnalyticsEvents.api.phone.apiSuccess,
          eventProperties: {
            nextStep: nextStep.identifier,
          },
        });
        dataStore.storePhoneNumber(phone);
        addNewStep(nextStep);
      })
      .catch((err) => {
        analyticsService.trackEvent({
          eventName: AnalyticsEvents.api.phone.apiFailed,
          eventProperties: {
            code: err?.response?.data?.code || 'unknown',
            error:
              err?.response?.data?.details?.phone || 'Something went wrong!!!',
          },
        });
        setState((prevState) => ({
          ...prevState,
          error:
            err?.response?.data?.details?.phone || 'Something went wrong!!!',
        }));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const trackNextButtonEvent = () => {
    if (step.identifier === DFCAuthStepIdentifiers.SignInEnterPhone) {
      analyticsService.trackEvent({
        eventName: AnalyticsEvents.signIn.phone.nextClicked,
        eventProperties: {},
      });
    } else if (step.identifier === DFCAuthStepIdentifiers.SignUpEnterPhone) {
      analyticsService.trackEvent({
        eventName: AnalyticsEvents.signUp.phone.nextClicked,
        eventProperties: {},
      });
    }
  };

  const trackScreenViewEvent = () => {
    if (step.identifier === DFCAuthStepIdentifiers.SignInEnterPhone) {
      analyticsService.trackEvent({
        eventName: AnalyticsEvents.signIn.phone.screen,
        eventProperties: {},
      });
    } else if (step.identifier === DFCAuthStepIdentifiers.SignUpEnterPhone) {
      analyticsService.trackEvent({
        eventName: AnalyticsEvents.signUp.phone.screen,
        eventProperties: {},
      });
    }
  };

  return {
    submitting: submitting,
    isValidData: state.isValidData,
    phoneNumber: state.phoneNumber,
    error: state.error,
    handlePhoneNumberChange,
    handleSubmit,
  };
};

export default useEnterPhone;
