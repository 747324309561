import React from 'react';

import { DFCAuthFlow } from './models/DFCAuthFlows';
import {
  DFCAuthErrorModel,
  DFCAuthSuccessModel,
} from './models/DFCAuthOutputModel';
import { DFCAuthStepIdentifiers } from './models/DFCAuthStepIdentifiers';
import { DFCAuthTenant } from './models/DFCAuthTenant';
import {
  EnterEmailOTPStep,
  EnterEmailOTPStepUsedFor,
} from './pages/EnterEmailOTP/EnterEmailOTPStep';
import { EnterPhoneStep } from './pages/EnterPhone/EnterPhoneStep';
import {
  AnalyticsService,
  CustomEventModel,
  IdentifyEventModel,
} from './services/analytics/Analytics';
import { AnalyticsServiceProvider } from './services/analytics/AnalyticsServiceProvider';
import { DataStoreProvider } from './services/dataStoreService/DataStoreProvider';
import { IDataStore } from './services/dataStoreService/IDataStore';
import { LocalDataStore } from './services/dataStoreService/LocalDataStore';
import {
  DFCAuthStepProcessorProvider,
  IDFCAuthStep,
} from './stepProcessor/DFCAuthStepProcessorProvider';
import { DFCAuthStepRenderer } from './stepProcessor/DFCAuthStepRenderer';

export interface VerifyEmailOTPDeepLinkProps {
  email: string;
  otp: string;
}
export interface DFCAuthProps {
  imagePrefix: string;
  tenant: DFCAuthTenant;
  flow: DFCAuthFlow;
  verifyEmailOTPDeepLinkProps?: VerifyEmailOTPDeepLinkProps;
  onDismiss: () => void;
  onAuthSuccess: (model: DFCAuthSuccessModel) => void;
  onAuthFailure: (error: DFCAuthErrorModel) => void;
  onAnalyticsEvent: (event: CustomEventModel | IdentifyEventModel) => void;
}

const DFCAuth: React.FC<DFCAuthProps> = (authProps: DFCAuthProps) => {
  const analyticsService = new AnalyticsService(authProps.onAnalyticsEvent);
  const dataStore: IDataStore = new LocalDataStore();

  const getInitialStep = (): IDFCAuthStep => {
    switch (authProps.flow) {
      case DFCAuthFlow.AuthFlowSignIn:
        return {
          identifier: DFCAuthStepIdentifiers.SignInEnterPhone,
        } as EnterPhoneStep;
      case DFCAuthFlow.AuthFlowSignUp:
        return {
          identifier: DFCAuthStepIdentifiers.SignUpEnterPhone,
        } as EnterPhoneStep;
      case DFCAuthFlow.AuthFlowVerifyEmailOTP:
        return {
          identifier: DFCAuthStepIdentifiers.SignInEnterEmailOTP,
          phone: dataStore.getPhoneNumber(),
          phoneOTP: dataStore.getPhoneOTP(),
          email: authProps.verifyEmailOTPDeepLinkProps?.email || '',
          emailOTP: authProps.verifyEmailOTPDeepLinkProps?.otp || '',
          usedFor: EnterEmailOTPStepUsedFor.VerifyEmailOTPDeepLink,
        } as EnterEmailOTPStep;
    }
  };
  return (
    <DataStoreProvider dataStore={dataStore}>
      <AnalyticsServiceProvider analyticsService={analyticsService}>
        <DFCAuthStepProcessorProvider initialStep={getInitialStep()}>
          <DFCAuthStepRenderer
            authPackageProps={authProps}
          ></DFCAuthStepRenderer>
        </DFCAuthStepProcessorProvider>
      </AnalyticsServiceProvider>
    </DataStoreProvider>
  );
};

export default DFCAuth;
