import { useCallback, useContext, useEffect, useState } from 'react';

import { EmailValidatorResult } from '../../core/EmailValidator';
import { Validator, ValidatorType } from '../../core/Validator';
import { DFCAuthStepIdentifiers } from '../../models/DFCAuthStepIdentifiers';
import { SubmitEmailRequestDTO } from '../../models/SubmitEmailRequestDTO';
import { AnalyticsEvents } from '../../services/analytics/AnalyticsEvents';
import { useAnalyticsService } from '../../services/analytics/AnalyticsServiceProvider';
import { useAuthService } from '../../services/authService/AuthServiceProvider';
import {
  DFCAuthProcessorContext,
  IDFCAuthStep,
} from '../../stepProcessor/DFCAuthStepProcessorProvider';
import { EnterEmailStep } from './EnterEmailStep';

interface EnterEmailState {
  email: string;
  error: string | undefined;
  isValidData: boolean;
}

const useEnterEmail = (step: EnterEmailStep) => {
  const { addNewStep } = useContext(DFCAuthProcessorContext);
  const authService = useAuthService();
  const analyticsService = useAnalyticsService();
  const validator = Validator.getValidator(
    ValidatorType.EMAIL,
    step.validation
  );
  const [submitting, setSubmitting] = useState(false);
  const [state, setState] = useState<EnterEmailState>({
    email: '',
    error: undefined,
    isValidData: false,
  });

  useEffect(() => {
    trackScreenViewEvent();
  }, []);

  const handleValidation = useCallback((newValue: string) => {
    // let valid = PhoneNumberUtil.validatePhoneNumber(newValue);
    let result = validator.validate(newValue) as EmailValidatorResult;
    if (result.isValid) {
      setState((prevState) => ({
        ...prevState,
        isValidData: true,
        error: undefined,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isValidData: false,
        error: 'Please enter valid email.',
      }));
    }
    return result.isValid;
  }, []);

  const handleEmailChange = (value: string) => {
    handleValidation(value);
    setState((prevState) => ({
      ...prevState,
      email: value,
    }));
  };

  const handleSubmit = () => {
    if (!state.isValidData) {
      return;
    }

    trackNextButtonEvent();
    setSubmitting(true);

    const emailRequest = {
      email: state.email,
      phone: step.phone,
      phoneOtp: step.phoneOTP,
    } as SubmitEmailRequestDTO;

    authService
      .submitEmail(emailRequest)
      .then((nextStep: IDFCAuthStep) => {
        analyticsService.trackEvent({
          eventName: AnalyticsEvents.api.email.apiSuccess,
          eventProperties: {
            nextStep: nextStep.identifier,
          },
        });
        addNewStep(nextStep);
      })
      .catch((err) => {
        analyticsService.trackEvent({
          eventName: AnalyticsEvents.api.email.apiFailed,
          eventProperties: {
            code: err?.response?.data?.code || 'unknown',
            error:
              err?.response?.data?.details?.phone || 'Something went wrong!!!',
          },
        });
        setState((prevState) => ({
          ...prevState,
          error:
            err?.response?.data?.details?.phone || 'Something went wrong!!!',
        }));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const trackNextButtonEvent = () => {
    if (step.identifier === DFCAuthStepIdentifiers.SignUpEnterEmail) {
      analyticsService.trackEvent({
        eventName: AnalyticsEvents.signUp.email.nextClicked,
        eventProperties: {},
      });
    }
  };

  const trackScreenViewEvent = () => {
    if (step.identifier === DFCAuthStepIdentifiers.SignUpEnterEmail) {
      analyticsService.trackEvent({
        eventName: AnalyticsEvents.signUp.email.screen,
        eventProperties: {},
      });
    }
  };

  return {
    submitting: submitting,
    isValidData: state.isValidData,
    email: state.email,
    error: state.error,
    handleEmailChange: handleEmailChange,
    handleSubmit,
  };
};

export default useEnterEmail;
